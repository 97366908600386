@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker__input-container {
  input {
    @apply shadow-sm block w-full rounded-md border-stone-400 hover:border-beige focus:ring-beige focus:border-beige text-sm sm:text-base leading-normal placeholder-stone;
  }
}

.date-picker-error {
  .react-datepicker__input-container {
    input {
      @apply border-red-300 focus:ring-0;
    }
  }
}

.react-datepicker {
  @apply flex p-4 bg-white border-stone-400 rounded;
}

.react-datepicker-popper[data-placement^="bottom"],
.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    &:before {
      border-bottom-color: #dbd7d4;
    }
    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px 9px;
      border-color: transparent transparent #ffffff transparent;
    }
  }
}

.react-datepicker-popper[data-placement^="top"] {
  .react-datepicker {
    min-height: 272px;
  }
}

.react-datepicker__current-month {
  @apply text-base leading-none mb-5;
}

.react-datepicker__header {
  @apply bg-transparent pt-0 pb-1 border-b border-stone-400;
}

.react-datepicker__month {
  @apply -mx-0.5 mb-0;
}

.react-datepicker__week {
  @apply flex;
}

.react-datepicker__day {
  @apply relative w-6 h-6 m-0.5 inline-flex items-center justify-center rounded-full text-xs;
  &:hover {
    @apply rounded-full;
  }
}

.react-datepicker__day-name {
  @apply relative text-xs w-6 my-0 mx-0.5;
}

.react-datepicker__day-names {
  @apply mb-0;
}
.react-datepicker__day--selected {
  @apply bg-beige hover:bg-beige;
}
.react-datepicker__day--keyboard-selected {
  @apply bg-beige hover:bg-beige;
}

.react-datepicker__day--in-range {
  @apply bg-beige-400 hover:bg-beige-400 rounded-none text-black;
  &:hover {
    @apply rounded-none;
  }
  &:before,
  &:after {
    content: "";
    @apply absolute top-0 w-0.5 h-full bg-beige-400;
  }
  &:before {
    content: "";
    @apply right-full;
  }
  &:after {
    content: "";
    @apply left-full;
  }
  &.react-datepicker__day--range-start,
  &.react-datepicker__day--range-end {
    &:after,
    &:before {
      @apply hidden;
    }
    &.react-datepicker__day--outside-month {
      @apply text-white;
    }
  }
}

.react-datepicker__day--in-selecting-range {
  &:not(.react-datepicker__day--in-range) {
    @apply bg-beige-400 hover:bg-beige-400 rounded-none text-black;
    &:before,
    &:after {
      content: "";
      @apply absolute top-0 w-0.5 h-full bg-beige-400;
    }
    &:before {
      content: "";
      @apply right-full;
    }
    &:after {
      content: "";
      @apply left-full;
    }
  }
  ,
  &.react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    @apply bg-beige hover:bg-beige rounded-full text-white;
    &:after,
    &:before {
      @apply hidden;
    }
  }
}
.react-datepicker__day--selecting-range-start {
  @apply bg-beige hover:bg-beige;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  @apply relative bg-beige hover:bg-beige text-white rounded-full;
  z-index: 1;
  &:hover {
    @apply rounded-full;
  }
}

.react-datepicker__day--outside-month {
  @apply text-iron-400 hover:text-white;
}

.react-datepicker__month-container + .react-datepicker__month-container {
  @apply pl-4 ml-4 border-l border-stone-400;
}

.react-datepicker__close-icon {
  @apply right-10 hover:opacity-50 pr-0 pt-px;
  &:after {
    background-color: transparent !important;
    content: "";
    @apply w-5 h-5 py-0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSIjQTU5Qzk0IiBhcmlhLWhpZGRlbj0idHJ1ZSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTYgMThMMTggNk02IDZsMTIgMTIiPjwvcGF0aD48L3N2Zz4=) !important;
    outline: none;
  }
}

.react-datepicker select.year-select {
  background-position: right center !important;
  @apply text-sm leading-none pl-1 pr-5 py-0.5 outline-none focus:outline-none appearance-none border-none ring-transparent focus:ring-transparent !important;
}
