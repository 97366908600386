.react-select-container {
  @apply max-w-full;
  &.small {
    .select-control {
      min-height: 30px;
      line-height: 1;
    }
    .react-select__value-container {
      @apply py-0;
    }
  }
  .selected-option {
    .profile-avatar {
      @apply hidden;
    }
  }

  &.isInvalid {
    .select-control {
      @apply border-red-300 text-red-600 focus:ring-0 focus:border-red-300 ring-0;
    }
  }

  .react-select__menu {
    @apply z-30;
  }

  .select-control {
    min-height: 42px;
    &.isFocused {
      .react-select__input {
        @apply not-sr-only;
      }
    }
  }

  .react-select__input {
    @apply sr-only;
  }

  input {
    &:focus {
      @apply border-none focus:outline-none focus:border-0 focus:ring-0;
    }
  }

  .react-select__value-container {
    @apply pl-3 pr-8 py-0.5 m-0 leading-tight;
  }

  .react-select__single-value {
    @apply relative transform-none top-0 left-0 p-0 m-0;
  }

  &.react-select_is-clearable {
    .react-select__value-container {
      @apply pr-12;
    }
  }
  .react-select__value-container--is-multi {
    @apply -mx-1;
    .select-placeholder {
      @apply px-1;
    }
  }
  .react-select__multi-value__label {
    @apply p-0 whitespace-normal;
    .profile-avatar {
      @apply hidden;
    }
  }
}
