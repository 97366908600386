@import "./components/datepicker.css";
@import "./components/checkbox.css";
@import "./components/select.css";
@import "./components/gantt.css";

html,
body {
  font-size: 16px;
}

@layer utilities {
  @variants responsive {
    .line-clamp-1 {
      -webkit-line-clamp: 1;
    }

    .line-clamp-2 {
      -webkit-line-clamp: 2;
    }

    .line-clamp-3 {
      -webkit-line-clamp: 3;
    }
  }
}

#root {
  @apply flex flex-col flex-1;
}

html,
body {
  @apply font-normal text-base text-black antialiased;
  font-size: 16px;
}

body {
  @apply flex flex-col min-h-screen m-0;
  background: #f6f5f4;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.container {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

button,
a {
  @apply outline-none focus:outline-none;
}

.empty-hidden {
  &:empty {
    @apply hidden;
  }
}

/* https://github.com/tailwindlabs/tailwindcss/discussions/8635 */
.opacity-0-fixed {
  opacity: 0;
}

.slide-over-sidebar {
  &:only-child {
    @apply max-w-none;
  }
  @screen xl {
    &:only-child {
      @apply max-w-2xl;
    }
  }
}

.input {
  &:placeholder-shown {
    ~ .input-clear-button {
      @apply invisible;
    }
  }
}

[data-slate-editor="true"] {
  min-height: 10em !important;
}

.break-words-hyphens {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@keyframes preloader-animation {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(2.5);
  }
}

.tracking-dashboard {
  height: 1500px;
  @media screen and (min-width: 850px) {
    height: 1200px;
  }
}
