@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./main.css";
@import "./fonts.css";
@import "./components/content.css";

body {
    font-family: "Avenir", "Arial", sans-serif;
}

