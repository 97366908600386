.content {
  @apply text-base text-black leading-tight whitespace-pre-line break-words;

  word-break: break-word;

  > * + * {
    @apply mt-4;
  }

  h1,
  h2 {
    @apply font-bold;
  }

  h1 {
    @apply text-xl md:text-2xl;

    + * {
      @apply mt-5

    }
  }

  h2 {
    @apply text-lg md:text-xl;

    + * {
      @apply mt-4

    }
  }

  a {
    @apply text-blue-500 underline hover:no-underline;
  }

  > span {
    @apply block;

    + ul,
    + ol {
      @apply mt-2;
    }
  }

  ol {
    list-style: number;

    @apply pl-4 space-y-1.5;
  }

  ul {
    padding-left: 1em;

    @apply space-y-1.5;

    li {
      &::before {
        content: "•";

        @apply text-beige inline-block align-baseline;

        font-size: 1.2em;
        width: 1em;
        height: 1em;
        margin-left: -1em;
      }
    }
  }

  blockquote {
    @apply block border-l-2 border-beige pl-3;
  }

  pre {
    @apply px-4 py-2 bg-gray-50 border rounded border-stone-400;
  }

  code {
    @apply bg-gray-100 py-0.5 px-2;
  }
}
