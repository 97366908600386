.gantt_tooltip {
  z-index: 25 !important;
  white-space: normal !important;
  max-width: 50vw;
}

.gantt_side_content.gantt_right {
  display: none !important;
}

.gantt {
  @apply w-full;
  height: calc(100vh - 64px - 72px - 58px);

  *:not(.gantt_link_arrow) {
    @apply border-iron-100 !important;
  }
  .gantt_right {
    @apply hidden;
  }

  .gantt_link_inline_color .gantt_line_wrapper {
    z-index: 2;
  }

  .gantt_thin_task {
    .gantt_right {
      @apply block;
    }
  }

  .gantt_task_scale {
    @apply text-sm md:text-base;
  }

  .gantt_grid_scale {
    @apply flex bg-beige-100;
  }

  .gantt_grid_head_cell {
    @apply inline-flex items-center text-sm text-iron first:pl-3 last:pr-3 md:first:pl-6 md:last:pr-6 !important;
  }

  .gantt_grid_head_start_date,
  .gantt_grid_head_duration {
    @apply text-right justify-end;
  }

  .gantt_task_cell {
    @apply border-r-0;
  }

  .gantt_grid_data {
    .gantt_row_project {
      @apply bg-iron-100 hover:bg-iron-100 w-full uppercase !important;
      .gantt_blank {
        @apply hidden;
      }
      .gantt_tree_content {
        @apply truncate;
      }
      .gantt_cell {
        @apply hidden w-full first:px-3 md:first:px-6;

        &:first-child {
          @apply flex;

          width: 100% !important;
        }
      }
    }

    .gantt_row {
      @apply w-full hover:bg-yellow-50;

      &.odd {
        @apply hover:bg-yellow-50;

        &.gantt_selected {
          @apply bg-yellow-50;
        }
      }

      &.gantt_selected {
        @apply bg-yellow-50;
      }
      /*
      &.nested-task .gantt_cell {
        @apply pl-4 md:first:pl-12;
      }
      */
    }

    .gantt_cell {
      @apply inline-flex h-full items-center text-sm md:text-base border-r-0 first:pl-3 last:pr-3 md:first:pl-6 md:last:pr-6;
    }

    .gantt_tree_content {
      @apply inline-block text-xs md:text-base text-black h-auto !important;
    }
  }

  .gantt_folder_open,
  .gantt_folder_closed,
  .gantt_project,
  .gantt_file,
  .gantt_blank {
    @apply hidden;
  }

  .gantt_task_row {
    &.odd {
      &.gantt_selected {
        @apply bg-yellow-50;
      }
    }

    &.gantt_selected {
      @apply bg-yellow-50;
    }
  }

  .gantt_selected {
    .gantt_task_line {
      @apply shadow-none;
    }
  }

  .gantt_task_line {
    @apply shadow-none cursor-pointer;

    border: none !important;

    &.gantt_selected {
      @apply shadow-none;
    }
  }

  .gantt_bar_task {
    @apply rounded-full transform translate-y-1/2 overflow-visible;

    height: auto !important;
    min-height: 16px;

    .gantt_task_content {
      @apply relative text-black transition-all leading-none overflow-visible px-2 py-1;

      font-size: 0;
      display: none;
    }

    &:hover {
      .gantt_task_content {
        @apply opacity-100;
      }
    }
  }

  .gantt_line_wrapper div,
  .gantt_task_link:hover .gantt_line_wrapper div {
    @apply bg-gray-300 shadow-none;
  }

  .gantt_link_arrow {
    @apply border-gray-300;
  }

  .gantt_task_line {
    &.gantt_task_inline_color {
      &.gantt_selected {
        @apply shadow-none;
      }
    }
  }

  .now-marker {
    @apply bg-red-200;
  }

  [data-column-name="start_date"] {
    max-width: 80px;

    &.gantt_grid_head_cell {
      max-width: none;
    }

    @screen md {
      max-width: 125px;

      &.gantt_grid_head_cell {
        max-width: 125px;
      }
    }
  }

  [data-column-name="duration"] {
    max-width: 60px;

    &.gantt_grid_head_cell {
      max-width: none;
    }

    @screen md {
      max-width: 150px;

      &.gantt_grid_head_cell {
        max-width: 150px;
      }
    }
  }

  [data-column-name="name"] {
    @apply flex-auto;
  }

  .gantt_task {
    .gantt_task_scale {
      .gantt_scale_cell {
        @apply text-iron;
      }
    }
  }

  .weekend {
    @apply bg-gray-50;
    &.gantt_scale_cell {
      @apply border-b;
    }
  }

  .gantt_selected {
    .weekend {
      @apply bg-yellow-50;
    }
  }


  .gantt_tree_indent{
    @apply w-2 sm:w-3 md:w-5;
  }

  .gantt_tree_icon.gantt_open,.gantt_tree_icon.gantt_close{
    @apply w-4 h-4 mr-2 mb-px;
  }
  .timeline_cell {
    @apply cursor-move;
  }
}
